<template>
  <aside v-if="news" class="media-item-page__aside">
    <div class="media-item-page__img">
      <a v-if="news.img" :href="media_endpoint + news.img.path" target="_blank">
        <ImgComponent :img="news.img" />
      </a>
    </div>
    <div v-if="news.published_at" class="media-item-page__info">
      <span class="media-item-page__info-title">Дата публикации:</span>
      <span class="media-item-page__info-description">{{ news.published_at | formatDate }}</span>
    </div>
    <div v-if="news['government_news_type']" class="media-item-page__info">
      <span class="media-item-page__info-title">Тип:</span>
      <span class="media-item-page__info-description">{{ news["government_news_type"].title }}</span>
    </div>
    <!--    <div class="media-item-page__info-svg-wrapper">-->
    <!--      <div class="media-item-page__info-svg" v-for="item in 4" :key="item">-->
    <!--        <IconComponent name="chat" />-->
    <!--      </div>-->
    <!--    </div>-->
  </aside>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "NewsAsideComponent",
  computed: {
    news() {
      return this.$store.state.news.news_item;
    },
    media_endpoint() {
      return this.$store.state._env.MEDIA_ENDPOINT;
    },
  },
  components: {
    IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.media-item-page {
  &__aside {
    display flex
    flex-direction column
  }

  &__info {
    display inline-flex
    padding 15px
    gap 5px
    background var(--white)
    border-top 1px solid var(--main-1-gray-light)
  }

  &__info-title {
    font-size: 0.875rem
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  &__info-description {
    font-size: 0.875rem
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__info-svg-wrapper {
    border-top 1px solid var(--main-1-gray-light)
    display grid
    grid-template-columns repeat(4, 1fr)
    background var(--white)
    padding 15px
  }

  &__info-svg {
    border 1px solid var(--gray-dark)
    border-left none
    height: 50px;
    display flex
    justify-content center
    align-items center

    &:nth-child(1) {
      border-left 1px solid var(--gray-dark)
    }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__img {
    width 100%

    img {
      height 227px
      object-fit cover
    }
  }
}
</style>
